import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"; // Correct import

const ContactPage = () => {
  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col md={12}>
          {" "}
          <br />
          <h2 className=" mb-3">Étapes de Prise de Rendez-vous</h2>
          <ul>
            <li>
              <strong>Étape 1 :</strong> RDV avec un{" "}
              <Link to="/therapeutes">psychiatre</Link> de l'équipe pour une
              première évaluation et mise en place d’un suivi.
            </li>
            <li>
              <strong>Étape 2 :</strong> Orientation vers la DBT ou d’autres
              approches si les critères diagnostics ne correspondent pas.
            </li>
            <li>
              <strong>Étape 3 :</strong> Prise de rendez-vous avec l’un des
              psychologues par mail :{" "}
              <a href="mailto:teampsychologues@centredbtfrancophone.org"><br/>
                teampsychologues@centredbtfrancophone.org{" "}
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          {" "}
          <br />
          <h2 className="r mb-3">Horaires des groupes :</h2>
          <ul>
            <li>
              <strong>Groupe adulte :</strong> le mercredi de 17h à 18h30 et de
              19h à 20h30. /  <a href="mailto:dbtadultes@centredbtfrancophone.org">
                dbtadultes@centredbtfrancophone.org{" "}
              </a>
            </li>
            <li>
              <strong>Groupe adolescent multifamille :</strong> le vendredi de
              18h30 à 20h et le mardi 19h-20h30. /<br/>  <a href="mailto:dbtados@centredbtfrancophone.org">
                dbtados@centredbtfrancophone.org{" "}
              </a>
            </li>            <li>
              <strong>Pour DBT Child :</strong>   <a href="mailto:dbtchild@centredbtfrancophone.org">
                dbtchild@centredbtfrancophone.org{" "}
              </a>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <h2 className="text-center mb-3">Notre Emplacement</h2>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.999398000048!2d2.304127!3d48.8550926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e670272fe63feb%3A0x4e542b902676069c!2s83+Av.+Bosquet,+75007+Paris!5e0!3m2!1sen!2sfr!4v1624893982321!5m2!1sen!2sfr"
              title="Emplacement"
              width="100%"
              height="600px"
              style={{ border: 0, borderRadius: "5px" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>{" "}
          <br />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6}>
          <h2 mb-3>Tarifs</h2>
          <ul>
            <li>
              <strong>Consultation avec un psychiatre</strong>
              <br />
              Conventionné secteur 2<br />
              Entre 100 et 200 euros la séance
              <br />
              Carte Vitale acceptée
              <br />
              Moyens de paiement : chèques, espèces et carte bancaire
            </li>
            <li>
              <strong>Consultation avec un psychologue</strong>
              <br />
              90 euros la séance
              <br />
              Moyens de paiement : chèques, espèces et virement bancaire
            </li>
          </ul>
        </Col>

        <Col md={6}>
          <h2 mb-3>Remboursements</h2>
          <ul>
            <li>
              Certaines mutuelles remboursent une partie des consultations.
            </li>
            <li>Remboursement avec la sécurité sociale et la mutuelle.</li>
            <li>
              Effectuez une demande d'indemnisation en cas de victime
              d'infraction :{" "}
              <a
                href="https://www.service-public.fr/particuliers/vosdroits/R18782"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.service-public.fr/particuliers/vosdroits/R18782
              </a>
            </li>
          </ul>
        </Col>
      </Row>{" "}
      <br />
    </Container>
  );
};

export default ContactPage;
