// src/components/NotFound.js
import React from "react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container className="text-align-center" style={{ marginTop: "10rem", height:"40vh" }}>
      <h1 style={{ fontSize: "3rem", marginBottom: "20px" }}>404</h1>
      <h2>Page non trouvée</h2>
      <p style={{ marginBottom: "20px" }}>
        Désolé, la page que vous recherchez n'existe pas.
      </p>
      <Link to="/">
        <Button variant="primary">Retour à l'accueil</Button>
      </Link>
    </Container>
  );
};

export default NotFound;
