import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/mignon-drole-organe-du-cerveau-humain-brule-medite-personnage_92289-2077.jpg.avif"; // Exemple d'importation d'image
import image from "../../images/TIPP_skills.png";

// Styles externalisés pour une meilleure lisibilité et cohérence
const styles = {
  section: {

  },
  heading: {
    color: "#105575",
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "1.5rem",
    textAlign: "center",
  },
  paragraph: {
    textAlign: "justify",
    marginBottom: "1.5rem",
    fontSize: "1.5rem",
  },
  list: {
    padding: 0,
    marginLeft: "2rem",
    marginBottom: "1.5rem",
  },
  listItem: {
    marginBottom: "0.5rem",
    fontSize: "1.1rem",
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "30px",
    boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "1.5rem",
  },
  iframe: {
    borderRadius: "30px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  heroSection: {
    position: "relative",
    width: "100%",
    height: "50vh",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "2rem",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  heroText: {
    color: "#ffffff",
    textAlign: "center",
    padding: "2rem",
    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
};

const DBT7 = () => (
  <section style={styles.section}>
    {/* Section Héros avec l'image en arrière-plan */}
    <div style={styles.heroSection}>
      <div
        className="position-absolute top-0 start-0 end-0 bottom-0"
        style={styles.overlay}
      />
      <div
        className="position-relative d-flex flex-column align-items-center justify-content-center h-100"
      >
        <h1 style={styles.heroText}>Tolérance à la Détresse</h1>
      </div>
    </div>

    {/* Contenu principal */}
    <Container>
      <Row className="mb-5 align-items-center">
        <Col md={12} lg={6}>
          <p style={styles.paragraph}>
            Ces compétences permettent de survivre aux crises émotionnelles et,
            aussi, de ne pas aggraver la situation. Une crise est une
            circonstance :
          </p>
          <ul style={styles.list}>
            <li style={styles.listItem}>De grande détresse,</li>
            <li style={styles.listItem}>De courte durée,</li>
            <li style={styles.listItem}>
              Donnant une forte impulsion à agir pour résoudre la crise
              immédiatement.
            </li>
            <li style={styles.listItem}>
              Moment où l’esprit émotionnel nous submerge et dont l’urgence à
              agir est le plus souvent une “conduite problème” : scarifications,
              alcoolisation, conduites à risque, etc.
            </li>
          </ul>
          <p style={styles.paragraph}>
            Les compétences de tolérance à la détresse apportent un soulagement
            immédiat, ici et maintenant, à la souffrance aiguë et minimisent le
            risque d’aggraver la situation par des conduites impulsives
            (urgences à agir). Ces compétences se fondent sur la philosophie Zen
            : accepter la réalité ne signifie pas l’approuver et la souffrance
            vient de la non-acceptation de cette réalité.
          </p>
        </Col>

        {/* Image à droite */}
        <Col md={12} lg={6} className="text-center">
          <img
            src={backgroundImage}
            alt="Tolérance à la Détresse"
            style={styles.image}
          />
        </Col>
      </Row>

      {/* Deuxième section avec vidéo */}
      <Row className="mb-5 align-items-center">
        <Col md={12} lg={6}>
          <p style={styles.paragraph}>Exemple de compétence :</p>
          <p style={styles.paragraph}>
            La compétence “TIPP” (temperature, intense aerobic exercise, paced
            breathing, and progressive muscle relaxation) est la plus connue des
            compétences de tolérance à la détresse. Il s’agit de l’application
            concrète des neurosciences dans la vie quotidienne : efficace et
            rapide, “TIPP” soulage la douleur ici et maintenant, en inhibant le
            système sympathique et en activant le système parasympathique. De
            cette façon, l’émotion désagréable est « privée » de « l’énergie »
            nécessaire à son activation. “TIPP” vise à changer la « neurochimie
            » cérébrale.
          </p>
        </Col>

        {/* Vidéo intégrée */}
        <Col md={12} lg={6} className="text-center">
          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/rbQWoDiVzDk"
            title="TIPP Training Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            style={styles.iframe}
          ></iframe>
        </Col>
      </Row>
    </Container>
  </section>
);

export default DBT7;
