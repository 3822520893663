import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const partenaires = {
  titre: "NOS PARTENAIRES",
  description:
    "Nous travaillons en collaboration avec les partenaires suivants :",

  liste: [
    {
      nom: (
        <a
          href="https://www.anthonylaroche.care/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Anthony LAROCHE
        </a>
      ),
      image: require("../../images/anthonyLaroche.jpeg"),
      specialite:
        "Hypnothérapeute, Maître-Praticien certifié en hypnose ericksonienne",
      description:
        "Il travaille en thérapie brève depuis plus de 10 ans et est spécialisé dans la libération émotionnelle liée, notamment, à tout type d’événement traumatique.",
    },

    {
      nom: (
        <a
          href=" https://lacliniquedesfemmes.org/contact/"
          target="_blank"
          rel="noopener noreferrer"
        >
          La Clinique des Femmes
        </a>
      ),
      image: require("../../images/cliniquedesfemmes.jpg"),
      description:
        "Nous prenons soin des femmes. De toutes les femmes et sans jugement. De la petite enfance à la ménopause. De toute la santé : gynécologique et obstétricale mais aussi psychique et émotionnelle.",
      contact: "01 44 25 00 00",
      adresse: "95 avenue de Bourdonnais, 75007 Paris",
    },
    {
      nom: (
        <a
          href="https://www.american-hospital.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          American Hospital of Paris{" "}
        </a>
      ),
      image: require("../../images/americanhospital.png"),

      specialite: "Diagnostic prénatal",
      description:
        "Un accès à tous les experts du dépistage et du diagnostic anténatal en un seul lieu pour réaliser l’ensemble des examens et consultations : échographies, one day test, dépistage prénatal non invasif et invasifs, génétique.",
      contact: "01 46 41 28 82",
      eTeamgmail: "centre-diagnostic-prenatal@ahparis.org",
      adresse: "55 boulevard du Château, 92200 Neuilly-sur-Seine",
    },
    {
      nom: (
        <a
          href="https://behavioraltech.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Beharioval Tech{" "}
        </a>
      ),
      image: require("../../images/behavioraltech.png"),
      specialite:
        " Institut de formation et siège de la Dialectic Behavioral Therapy",
      description: "",
    },
    {
      nom: (
        <a
          href="https://dbtiberoamerica.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          DBTIberoAmerica{" "}
        </a>
      ),
      image: require("../../images/DBTIberoamerica.png"),
      specialite: "Siège de la Dialectic Behavioral Therapy en Amérique Latine",
      description: "",
    },
  ],
};

const teamMembers1 = [
  {
    nom: "Dr. Luís Alvarez",
    specialite: "Psychiatre et pédopsychiatre",
    Teamgmail: "luis.alvarez@centredbtfrancophone.org",
    doctolibLink:
      "https://www.doctolib.fr/psychiatre-de-l-enfant-et-de-l-adolescent/paris/luis-alvarez-zalamea",
    image: require("../../images/luis_profile.jpeg"),
    description: (
      <>
        <p>Ancien chef de clinique de l’hôpital Necker enfants malades</p>
        <p>Ancien médecin adjoint de l’institut de puériculture de Paris</p>
        <p>
          Co-fondateur avec Amina YAMGNANE de la clinique des femmes en
          septembre 2019
        </p>
        <p>
          Pédopsychiatre au pôle de santé de la mère et de l’enfant de l’AHP
          depuis 2017
        </p>
        <p>Auteur de :</p>
        <ul>
          <li>La psychiatrie du bébé, collection Que sais-je, 2013</li>
          <li>
            Psychiatrie et psychologie de la grossesse : de la femme à la mère,
            Odile Jacob, 2015
          </li>
          <li>
            La démarche palliative en médecine périnatale, Relations médicales,
            2016
          </li>
        </ul>
        <p>
          Auteur d’une cinquantaine d’articles dans des revues à comité de
          rédaction
        </p>
        <p>Auteur de nombreux articles dans des textes de référence.</p>
        <p>
          Accrédité « DBT intensive course » par The Behavioral Tech (Seattle,
          USA) Institute en 2023
        </p>
        <p>
          Entraînement à la thérapie d’exposition prolongée par le Pr. Barbara
          Rothbaum de la faculté de médecine d’Emory à Atlanta, USA.
        </p>
      </>
    ),
  },
  {
    nom: "Dr. Laure Woestelandt",
    specialite: "Psychiatre et pédopsychiatre",
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    doctolibLink:
      "https://www.doctolib.fr/psychiatre/paris/laure-woestelandt-paris",
    image: require("../../images/Laure_Woestand.png"),
    description: (
      <>
        <p>
          Le Dr Woestelandt , pédopsychiatre, spécialisée en psychiatrie
          périnatale, accueil les parents dès leur projet de conception , en pré
          et post-partum, ainsi que les bébés pour un dépistage précoce des
          troubles du neuro-développement ou pour des troubles somatiques (
          trouble du sommeil, RGO ect..)
        </p>
        <p>
          Elle propose un soutient à la parentalité , un suivi des troubles
          psychiques périnataux ( dépression périnatale) et un soutient aux
          relations précoces parents-bébé.
        </p>
        <p>
          Elle reçoit les enfants et adolescents pour toutes problématiques (
          TDAH, trouble des apprentissages, dépression, trouble bipolaire, TSA
          ect..).
        </p>
        <p>
          Elle propose également un soutient aux parents pour des enfants
          porteurs de handicap, de maladie génétique. Formée à la Guidance
          Intéractive, technique de psychothérapie par vidéo-feed-back, elle
          peut vous proposer des séances si cela apparait pertinent.
        </p>
      </>
    ),
  },
];

const teamMembers2 = [
  {
    nom: "Philippine Lebert",
    specialite: "Psychologue clinicienne",
    TeamMemberDescription: (
      <>
        {" "}
        <p>
          Psychologue formée au programme 'DBT S' par le Psychwire. Diplômée de
          l'École de Psychologues Praticiens, elle considère la psychothérapie
          comme un moyen d'accompagner chaque personne vers un mieux-être, dans
          une relation de collaboration et d'échange, en fonction de ses
          objectifs propres.
        </p>
        <p>
          Son approche intègre différentes techniques et méthodes pour répondre
          aux besoins uniques de chaque individu qu'elle rencontre.
        </p>
      </>
    ),
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    doctolibLink: "",
    image: require("../../images/philippineLebert.jpg"),
    description: "Détails supplémentaires sur Philippine Lebert.",
  },
  {
    nom: "Laura Lunel",
    specialite: "Psychologue clinicienne",
    TeamMemberDescription: (
      <>
        <p>
          Psychologue clinicienne et thérapeute formée en DBT intensive
          dispensée par Behavioral Tech, elle s'est spécialisée dans les prises
          en charge du psychotraumatisme, notamment avec une formation avec
          Psychwire.
        </p>
      </>
    ),
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    doctolibLink: "",
    image: require("../../images/laura_profile.jpeg"),
    description: "Détails supplémentaires sur Laura Lunel.",
  },
  {
    nom: "Maya Dewsbery",
    specialite: "Psychologue clinicienne",
    TeamMemberDescription: (
      <>
        {" "}
        <p>
          Psychologue clinicienne et thérapeute DBT accréditée « DBT intensive
          course » par The Behavioral Tech Institute en 2023.
        </p>
        <p>
          Au Centre Francophone de DBT, je suis spécialisée dans les prises en
          charge des adultes (DBT Standard) et des adolescents (DBT Teens).
        </p>
        <p>
          Je suis également formée à la thérapie ICV (Intégration du Cycle de la
          Vie) pour traiter les psychotraumatismes simples et complexes.
        </p>{" "}
        <p>
          Je co-anime les groupes dans le 7e arrondissement et mes prises en
          charges individuelles se déroulent dans mon cabinet situé dans le 12e
          arrondissement ou en visio.
        </p>
      </>
    ),
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    doctolibLink: "https://www.doctolib.fr/psychologue/paris/maya-dewsbery",
    image: require("../../images/maya_profile_picture.jpeg"),
    description: "Détails supplémentaires sur Maya Dewsbery.",
  },
  {
    nom: "Paula Pellegrini",
    specialite: "Psychologue clinicienne",
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    TeamMemberDescription: (
      <>
        {" "}
        <p>
          Psychologue clinicienne formée aux Thérapies Comportementales et
          Cognitives (TCC) et à la Thérapie Comportementale et Dialectique
          (DBT).
        </p>
        <p>
          Je suis spécialisée dans la prise en charge des traumatismes à l’aide
          des thérapies appuyées par les preuves, comme l'EMDR et la Thérapie
          d’Exposition Prolongée.
        </p>
        <p>
          Je suis également formée aux différents protocoles et programmes
          spécifiques à la DBT : <br />
          DBT-CPTSD de Martin Bohus et DBT-PE de Melanie Harned pour les
          troubles de stress post-traumatique simples et complexes, DBT-SUD pour
          les troubles d’usage des substances ; ainsi que la Thérapie RO-DBT de
          Thomas Lynch pour les troubles de contrôle excessif.{" "}
        </p>
      </>
    ),
    doctolibLink:
      "https://www.aftcc.org/map/partner/pellegrini-paula-pellegrini-paula-25219/12aafc15-410a-44a7-b9be-9ee23a02c777",
    image: require("../../images/Laura_pellegrini.jpeg"),
    description: "Détails supplémentaires sur Paula Pellegrini.",
  },
  {
    nom: "Inès Clowez",
    specialite: "Psychologue clinicienne",
    TeamMemberDescription: (
      <>
        {" "}
        <p>
          Psychologue clinicienne, diplômée de l’École de Psychologues
          Praticiens de Paris, je suis formée au programme “DBT Skills” ainsi
          qu’au programme “DBT Intensive course” par l’institut de formation
          Behavioral Tech.
        </p>
        <p>
          Je propose des consultations pour enfants, adolescents, adultes ainsi
          que des consultations périnatales.
        </p>
      </>
    ),
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    doctolibLink: null,
    image: require("../../images/Screenshot 2024-09-18 at 13-13-07 Photo site internet - eliasseddiki49@gmail.com - Gmail.png"),
    description: "Détails supplémentaires sur Inès Clowez.",
  },
  {
    nom: "Ilona Henaff ",
    specialite: "Psychologue clinicienne",
    TeamMemberDescription: (
      <>
        <p>
          Psychologue clinicienne diplômée de l’École de Psychologue Praticiens,
          je suis formée à la Thérapie Comportementale Dialectique via le
          programme DBT Skills.
          <br /> <br />
          Je me spécialise dans la prise en charge des psychotraumatismes qu’ils
          soient récents ou anciens. En tant qu’ancienne sportive de
          haut-niveau, mon intérêt porte de longue date sur la transmission
          d’outils de régulation émotionnelle et de gestion du stress. <br />
          <br />
          Je travaille dans une relation authentique d’échange et de
          collaboration, qui me permet d’accompagner les patientes et patients
          dans le respect de leur singularité et de leurs valeurs. Les
          consultations pour adultes et adolescent(e)s, se déroulent en cabinet
          ou en visio.
        </p>
      </>
    ),
    Teamgmail: "teampsychologues@centredbtfrancophone.org",
    doctolibLink: null,
    image: require("../../images/photo_Ilona.jpeg"),
    description: "Détails supplémentaires sur ?",
  },
];

const TeamPartenairesPage = () => {
  const [show, setShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (member) => {
    setSelectedMember(member);
    setShow(true);
  };

  return (
    <>
      <Container className="my-5">
        {/* Section Équipe */}
        <h2 className="text-center mb-4">NOTRE ÉQUIPE</h2>

        {/* Première rangée */}
        <Row className="mb-4">
          {teamMembers1.map((member, index) => (
            <Col md={6} key={index} className="mb-4">
              <Card className="text-center no-border">
                <div
                  className="team-image-container"
                  onClick={() => handleShow(member)}
                >
                  {member.image ? (
                    <img
                      className="team-image"
                      src={member.image}
                      alt={member.nom}
                    />
                  ) : (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: "250px",
                        width: "250px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                      }}
                    >
                      <p className="text-muted">Image non disponible</p>
                    </div>
                  )}
                </div>
                <Card.Body>
                  <Card.Title>{member.nom}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {member.specialite}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Texte ajouté sous l'équipe */}
        <p className="text-center mb-4">
          Toute notre équipe est formée à la thérapie DBT auprès de l’institut
          de formation BTECH. Nous proposons des protocoles DBT, ainsi que
          d’autres approches thérapeutiques qui viennent compléter le
          traitement.
          <br />
          Le centre se compose de 2 psychiatres, 6 psychologues, et un
          thérapeute pratiquant l'EMDR, l'hypnose et l'acupression.
          <br />
          L'équipe proposant les protocoles DBT est constituée de 2 psychiatres
          et 6 psychologues :
          <br />
          Pour la prise de rendez-vous DBT,{" "}
          <i>
            il faut d'abord prendre rendez-vous avec un médecin psychiatre. Pour
            prendre rendez-vous, cliquez sur le nom du praticien (redirection
            Doctolib) ou adressez un mail à l’adresse indiquée.
          </i>
        </p>
      </Container>

      <Container>
        {/* Deuxième rangée */}
        <Row>
          {teamMembers2.map((member, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="text-center no-border">
                <div
                  className="team-image-container"
                  onClick={() => handleShow(member)}
                >
                  {member.image ? (
                    <img
                      className="team-image"
                      src={member.image}
                      alt={member.nom}
                    />
                  ) : (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        height: "250px",
                        width: "250px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                      }}
                    >
                      <p className="text-muted">Image non disponible</p>
                    </div>
                  )}
                </div>
                <Card.Body>
                  <Card.Title>{member.nom}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {member.specialite}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Section Partenaires */}
        <h2 className="text-center mb-4">{partenaires.titre}</h2>
        <p className="text-center mb-4">{partenaires.description}</p>
        <Row>
          {partenaires.liste.map((partenaire, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="text-center no-border">
                <div className="partner-image-container">
                  {partenaire.image ? (
                    <img
                      className="partner-image"
                      src={partenaire.image}
                      alt={partenaire.nom}
                    />
                  ) : (
                    <div className="no-image-container">
                      <p className="no-image-text">Image non disponible</p>
                    </div>
                  )}
                </div>
                <Card.Body>
                  <Card.Title>{partenaire.nom}</Card.Title>
                  {partenaire.specialite && (
                    <Card.Subtitle className="mb-2 text-muted">
                      {partenaire.specialite}
                    </Card.Subtitle>
                  )}
                  <Card.Text>{partenaire.description}</Card.Text>
                  {partenaire.adresse && (
                    <Card.Text>
                      <strong>Adresse :</strong> {partenaire.adresse}
                    </Card.Text>
                  )}
                  {partenaire.contact && (
                    <Card.Text>
                      <strong>Contact :</strong> {partenaire.contact}
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {selectedMember && (
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{selectedMember.nom}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedMember.image && (
                <img
                  src={selectedMember.image}
                  alt={selectedMember.nom}
                  className="rounded-circle mb-3 mx-auto d-block"
                  style={{ maxWidth: "90%" }}
                />
              )}
              <h5>{selectedMember.specialite}</h5>
              <p>
                {selectedMember.TeamMemberDescription ||
                  selectedMember.description}
              </p>
              {selectedMember.Teamgmail && (
  <p style={{ fontSize: "1.25rem", color: "#ff6347" }}>
    <strong>Email:</strong> <br/>
    <a href={`mailto:${selectedMember.Teamgmail}`} style={{ color: "#ff6347" }}>
      {selectedMember.Teamgmail}
    </a>
  </p>
)}

            </Modal.Body>
            <Modal.Footer>
              {selectedMember.doctolibLink && (
                <Button
                  variant="primary"
                  href={selectedMember.doctolibLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prendre Rendez-vous
                </Button>
              )}
              <Button variant="secondary" onClick={handleClose}>
                Fermer
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <style jsx>{`
          /* Styles globaux pour les cartes */
          .card {
            border: none;
            box-shadow: none;
            background: transparent;
          }

          /* Style pour les conteneurs d'image de l'équipe */
          .team-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-radius: 50%;
            overflow: hidden;
            width: 250px;
            height: 250px;
            margin: 0 auto;
          }

          /* Style pour les images de l'équipe */
          .team-image {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Remplir le conteneur tout en gardant les proportions */
            border-radius: 50%;
          }

          /* Style pour les images du modal */
          .modal-body img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
          }

          /* Style pour les images des partenaires */
          .partner-image-container {
            height: 250px;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 10px;
          }

          .partner-image {
            width: 100%;
            height: 100%;
            object-fit: contain; /* Ajuster l'image pour qu'elle soit entièrement visible */
          }

          /* Style pour les images non disponibles */
          .no-image-container {
            width: 250px;
            height: 250px;
            background-color: #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .no-image-text {
            color: #888;
          }
          /* Styles pour l'image d'Anthony (et d'autres membres si tu veux appliquer à tous) */
          .team-image-container.anthony {
            border-radius: 50%; /* Arrondir complètement */
            width: 250px;
            height: 250px;
            overflow: hidden;
            margin: 0 auto;
          }

          .team-image.anthony {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Assurer que l'image est bien ajustée à l'intérieur */
            border-radius: 50%; /* Arrondir l'image */
          }
        `}</style>
      </Container>
    </>
  );
};

export default TeamPartenairesPage;
