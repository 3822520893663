import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const CustomLink = ({ to, children }) => (
    <Link to={to} onClick={scrollToTop} style={styles.link}>
      {children}
    </Link>
  );

  return (
    <footer style={styles.footer}>
      <Container>
        <Row className="footer-content">
          <Col xs={12} md={4} style={styles.footerSection}>
            <h6 style={styles.heading}>À PROPOS</h6>
            <ul style={styles.list}>
              <li>
                <CustomLink onClick={scrollToTop} to="/">
                  Accueil
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/contact">
                  Contactez-nous
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/therapeutes">
                  Partenaires
                </CustomLink>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} style={styles.footerSection}>
            <h6 style={styles.heading}>THÉRAPIE</h6>
            <ul style={styles.list}>
              <li>
                <CustomLink
                  onClick={scrollToTop}
                  to="/psychotherapie_individuelle"
                >
                  Psychothérapie Individuelle
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/groupe_entrainement">
                  Groupe de Compétence
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/contact-entre-seances">
                  Contact entre Séances
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/reunion-equipe">
                  Réunion d'Équipe
                </CustomLink>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} style={styles.footerSection}>
            <h6 style={styles.heading}>COMPÉTENCES DBT</h6>
            <ul style={styles.list}>
              <li>
                <CustomLink onClick={scrollToTop} to="/mindfulness">
                  Pleine Conscience
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/regulation-emotionnelle">
                  Régulation Émotionnelle
                </CustomLink>
              </li>
              <li>
                <CustomLink
                  onClick={scrollToTop}
                  to="/interpersonal-relationship"
                >
                  Efficacité Interpersonnelle
                </CustomLink>
              </li>
              <li>
                <CustomLink onClick={scrollToTop} to="/distress">
                  Tolérance à la Détresse
                </CustomLink>
              </li>
            </ul>
          </Col>
        </Row>
        <br />
        <Row className="footer-bottom">
          <Col xs={12} md={12} style={styles.footerContact}>
            <p>
              <FaMapMarkerAlt /> 83 Avenue Bosquet, Paris, France
            </p>
            <p>
              <FaPhoneAlt /> 01 45 25 00 00
            </p>
            <p>
              <FaEnvelope /> teampsychologues@centredbtfrancophone.org
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={styles.footerCopyright}>
            <p>
              &copy; {currentYear} Centre Francophone de DBT. Tous droits
              réservés.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "rgb(199, 132, 106)",
    color: "#f5f5f5",
    padding: "40px 0",
    fontFamily: "'Arial', sans-serif",
  },
  footerSection: {
    marginBottom: "2rem",
  },
  heading: {
    fontWeight: "bold",
    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
    marginBottom: "1rem",
  },
  list: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  link: {
    color: "#ffffff",
    textDecoration: "none",
    transition: "color 0.3s ease",
    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
    fontSize: "1rem",
  },
  footerContact: {
    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
    paddingTop: "2rem",
  },
  footerCopyright: {
    textAlign: "center",
    marginTop: "2rem",
    fontSize: "0.9rem",
    opacity: 0.8,
  },
};

export default Footer;
