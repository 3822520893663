import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/Efficacité-interpersonnelle.jpg";

const EfficaciteInterpersonelle = () => {
  return (
    <>
      <div
        className="hero-image mb-4"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          height: "50vh",
          position: "relative",
        }}
      >
        <div
          className="hero-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        <div
          className="hero-text text-center text-white"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
          }}
        >
          <h1 className="display-4 fw-bold">Efficacité Interpersonnelle</h1>
        </div>
      </div>
      <Container>
        {" "}
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12">
              <p className="lead fs-4">
                Ce module permet de construire des liens sains avec autrui, en
                s'appuyant sur la pleine conscience : sans agressivité et tenant
                compte d'autrui. Trouver un équilibre entre nos besoins et ceux
                d'autrui.
              </p>
              <ul className="list-unstyled">
                {[
                  "Faire une demande affirmée et savoir formuler un refus.",
                  "Prendre soin des relations en validant les autres.",
                  "Augmenter le respect de soi en s'autovalidant.",
                  "Faire face à l'invalidation.",
                  "Adopter une posture dialectique dans la relation.",
                ].map((item, index) => (
                  <li key={index} className="mb-3 fs-5">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Row className="mb-5 justify-content-center">
            <Col md={12}>
              <div
                className="ratio ratio-16x9"
                style={{ margin: "2rem 0" }}
              >

                <iframe
                  src="https://www.youtube.com/embed/N4QoYR0YNDQ"
                  title="DEAR MAN"
                  allowFullScreen
                  className="rounded shadow"

                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default EfficaciteInterpersonelle;
