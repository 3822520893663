import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/HomePage/Home";
import NavBar from "./components/Navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer/Footer";
import GlobalStyle from "../src/globalStyle.css";
import DBT from "./pages/DbtPage/DBT";
import DBT1 from "./pages/DbtPage/DBT1";
import DBT2 from "./pages/DbtPage/DBT2";
import PleineConscience from "./pages/DbtPage/PleineConscience";
import RegulationEmotionelle from "./pages/DbtPage/RegulationEmotionnelle";
import ReunionEquipe from "./pages/DbtPage/ReunionEquipe";
import ToleranceDetress from "./pages/DbtPage/ToleranceDetresse";
import ContactEntreSeances from "./pages/DbtPage/ContactEntreSeances";
import PourQui from "./pages/PourQui/PourQui";
import TeamCarousel from "./pages/TeamPage/Team";
import EfficaciteInterpersonelle from "./pages/DbtPage/EfficaciteInterpersonnelle";
import GroupeCompetence from "./pages/DbtPage/GroupeCompetence";
import PsychotherapieIndividuelle from "./pages/DbtPage/PsychotherapieIndividuelle";
import Contact from "./pages/Contact/Contact";
import NotFound from "./components/NotFound/NotFound";

function App() {

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dbt" element={<DBT />} />
        <Route path="/dbt1" element={<DBT1 />} />
        <Route path="/prise-en-charge" element={<DBT2 />} />
        <Route path="/distress" element={<ToleranceDetress />} />
        <Route path="/regulation-emotionnelle" element={<RegulationEmotionelle />} />
        <Route path="/contact-entre-seances" element={<ContactEntreSeances />} />
        <Route path="/mindfulness" element={<PleineConscience />} />
        <Route path="/reunion-equipe" element={<ReunionEquipe />} />
        <Route path="/therapy" element={<PourQui />} />
        <Route path="/therapeutes" element={<TeamCarousel />} />
        <Route path="/interpersonal-relationship" element={<EfficaciteInterpersonelle />} />
        <Route path="/groupe_entrainement" element={<GroupeCompetence />} />
        <Route path="/psychotherapie_individuelle" element={<PsychotherapieIndividuelle />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
