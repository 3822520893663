import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/bol_1.jpeg";

const DBT2 = () => (
  <>
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "50vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "2rem",
      }}
    >
      <div
        className="position-absolute top-0 start-0 end-0 bottom-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      />
      <div
        className="position-relative d-flex flex-column align-items-center justify-content-center h-100"
        style={{ color: "#ffffff", padding: "2rem" }}
      >
        <h1
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#ffffff",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          La Pleine Conscience
        </h1>
      </div>
    </div>

    <Container>
      <Row className="justify-content-center">
        <Col md={12} lg={8} style={{ marginBottom: "2rem" }}>
          <p style={{ margin: "1rem auto" }}>
            Les compétences de Pleine Conscience sont la base de la régulation
            émotionnelle. Apprises en groupe et intégrées en psychothérapie
            individuelle, elles sont indispensables pour l’assimilation des
            autres modules. La Pleine Conscience en DBT diffère de la relaxation
            pleine conscience ou de la méditation, qui sont des pratiques pour
            atteindre l’état de Pleine Conscience.
          </p>

          <p style={{ margin: "1rem auto" }}>
            La Pleine Conscience consiste à être pleinement présent « ici et
            maintenant », en contact avec soi et en contact avec les autres.
            Nous pouvons comparer la Pleine Conscience à un entraînement pour
            “muscler” le cerveau et focaliser l’attention sur une seule chose à
            la fois, et notamment :
          </p>
          <ul
            style={{ textAlign: "left", maxWidth: "800px", margin: "0 auto" }}
          >
            <li style={{ marginBottom: "0.5rem" }}>Nos émotions,</li>
            <li style={{ marginBottom: "0.5rem" }}>
              Nos sensations corporelles,
            </li>
            <li style={{ marginBottom: "0.5rem" }}>Nos pensées,</li>
            <li style={{ marginBottom: "0.5rem" }}>Nos urgences à agir.</li>
          </ul>
          <p style={{ margin: "1rem auto" }}>
            Les compétences de Pleine Conscience nous apprennent à identifier,
            puis à modifier :
          </p>
          <ul
            style={{ textAlign: "left", maxWidth: "800px", margin: "0 auto" }}
          >
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>“L’esprit émotionnel” :</strong> nous sommes « gouvernés »
              par nos émotions.
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              <strong>“L’esprit rationnel” :</strong> nous sommes « gouvernés »
              par la pensée rationnelle, scientifique, froide et factuelle.
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-5 justify-content-center">
        <Col md={12}>
          <div className="ratio ratio-16x9" style={{ margin: "2rem 0" }}>
            <iframe
              src="https://www.youtube.com/embed/V-_yt7mT3JA"
              title="TIPP Training Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: "15px" }}
            ></iframe>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}  style={{ marginBottom: "2rem" }}>
          <p style={{ margin: "1rem auto" }}>
           <i>In fine</i> , la Pleine Conscience vise à nous rapprocher le plus
            possible de notre “esprit sage”, état d’équilibre et de synthèse
            entre l’esprit émotionnel et l’esprit rationnel. L’esprit sage nous
            fait expérimenter la régulation des émotions et nous permet de
            prendre des décisions efficaces et adaptées à la réalité.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default DBT2;
