import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/Groupe_therapeutes.jpg";
import techbehavioral from "../../images/behavioraltech.png";
const styles = {
  heroSection: {
    position: "relative",
    width: "100%",
    height: "50vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginBottom: "2rem",

    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  heroText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "rgba(0,0,0,0.7)",

    fontSize: "2rem",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0,0,0,0.8)",
  },
  sectionText: {
    marginLeft: "20px",
    marginBottom: "1rem",
  },
};

const ReunionEquipe = () => {
  return (
    <section>
      {/* First Hero Section */}
      <div
        style={{
          ...styles.heroSection,
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        />
        <div
          className="position-relative d-flex flex-column align-items-center justify-content-center h-100"
          style={{ padding: "2rem" }}
        >
          <h1
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#000000",
              textShadow: "2px 2px 4px rgba(0,0,0,0.8)",
              textAlign: "center",
            }}
          >
            Réunion d'équipe des psychothérapeutes DBT
          </h1>
        </div>
      </div>

      <Container>
        <Row className="mb-5 align-items-center">
          <Col md={12}>
            <br />
            <p style={styles.sectionText}>
              La réunion d’équipe des psychothérapeutes DBT est un élément
              essentiel du protocole DBT. C'est une forme de "thérapie pour les
              thérapeutes", qui a lieu chaque semaine et dure entre une heure et
              une heure et demie, selon la taille de l'équipe. Tous les membres
              de l'équipe sont inclus, car le protocole DBT nécessite une prise
              en charge en équipe des patients. <br />
              <br />
              <strong>En général, une équipe DBT est constituée :</strong>
              <ul>
                <li>
                  D’au moins un psychiatre qui a mené l’évaluation, établi un
                  diagnostic, proposé une éventuelle médication, posé
                  l’indication de DBT et qui continue à assurer les
                  consultations, souvent mensuelles, auprès du patient.
                </li>
                <li>Des psychothérapeutes individuels.</li>
                <li>Du leader et du co-thérapeute du groupe de compétences.</li>
                <li>Des autres cliniciens de l'équipe DBT.</li>
              </ul>
              <br />
              <strong>Les objectifs de la réunion d’équipe sont :</strong>
              <ul>
                <li>
                  Offrir un soutien aux cliniciens impliquésdans les prises en
                  charge, ce qui est d'autant plus essentiel lorsque la
                  situation des patients est exigeante.
                </li>
                <li>
                  Fournir un espace de réflexionpour analyser les situations
                  cliniques, en accord avec les principes et les fondements de
                  DBT. Cela permet de proposer les meilleurs soins aux patients
                  et de les réajuster en fonction de leur évolution.
                </li>
                <li>
                  Créer un espace d'apprentissage pour les thérapeutes, tant sur
                  ce que la clinique avec les patients leur apprend sur
                  eux-mêmes et leur besoin de formation, que sur ce que les
                  thérapeutes peuvent s'apporter au sein du groupe en termes
                  d'expériences, d'expertise et d'actualisation en DBT et en
                  santé mentale en général.
                </li>
              </ul>
              Le processus de changement en DBT repose largement sur la capacité
              des thérapeutes à enseigner aux patients comment ne pas souffrir
              par l'acquisition de compétences de régulation émotionnelle, puis
              à les aider à développer de nouvelles stratégies "saines” de
              fonctionnement. Ces différents niveaux d'apprentissages
              constituent un véritable "pari" sur la psycho-éducation,
              considérée ici comme le premier soin. Ainsi, avec leur thérapeute
              et en groupe, les patients font l'expérience qu'il est possible de
              ne pas souffrir et de créer, maintenir et protéger une dynamique
              constructive qui nous fait vivre "a life worth living", selon la
              célèbre formulation de Marsha Linehan.
            </p>
          </Col>
        </Row>
        <Row className="mb-5 align-items-center">
          <Col md={6}>
            <p style={styles.sectionText}>
              <strong>
                Consultation d’équipe avec Tony DuBose, directeur du Behavioral
                Tech Institute{" "}
              </strong>
              : La consultation d’équipe fait partie des réunions d’équipe,
              permettant à l’ensemble des thérapeutes DBT de forger une pratique
              commune, accroître la cohérence et acquérir de nouvelles
              compétences sur le plan théorique et pratique. L’intérêt de la
              réunion d’équipe est de partager avec un tiers extérieur,
              instructeur de Behavioral Tech, nos pratiques, nos
              questionnements, nos doutes, afin d’offrir à nos patients, la
              meilleure version de notre équipe. Elle a lieu une fois par mois,
              pendant une heure.
            </p>
          </Col>{" "}
          <Col md={6}>
            <img
              src={techbehavioral}
              alt="Consultation d'équipe"
              style={{
                width: "100%", // L'image prendra toute la largeur de la colonne
                height: "320px", // La hauteur s'ajustera automatiquement
                borderRadius: "15px", // Optionnel : pour arrondir les coins de l'image
              }}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReunionEquipe;
