import React from "react";
import { Container, Carousel } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import CarouselImage from "../../images/closeup_flower.jpeg";

const ResponsiveCarousel = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });

  const styles = {
    carouselContainer: {
      maxWidth: "100%",
      maxHeight: "100%",
      position: "relative", 
      margin: "0 auto",
    },
    carouselItem: {
      padding: isMobile ? "1rem" : isTablet ? "1.5rem" : "2rem",
      minHeight: isMobile ? "300px" : isTablet ? "350px" : "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: isMobile ? "1rem" : isTablet ? "3rem" : "5rem",
      paddingLeft: isMobile ? "1rem" : isTablet ? "3rem" : "5rem",
      backgroundImage: `url(${CarouselImage})`,
      height: isMobile ? "70vh" : isTablet ?"60vh" : "55vh",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      position: "relative",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    content: {
      position: "relative",
      zIndex: 1,
      width: "100%",
      alignSelf: "flex-end",
      marginBottom: "2rem", 
      padding: "0 1rem", 
    },
    carouselHeading: {
      fontSize: isMobile ? "1.5rem" : isTablet ? "2rem" : "2.5rem",
      fontWeight: "bold",
      textAlign: "center",
      color: "rgb(100, 151, 202)",
      marginBottom: "1rem",
      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
    },
    carouselText: {
      fontSize: isMobile ? "1rem" : isTablet ? "1.125rem" : "1.25rem",
      textAlign: "center",
      color: "#fff",
      maxWidth: isMobile ? "100%" : isTablet ? "90%" : "80%",
      margin: "0 auto",
    },
  };

  const carouselItems = [
    {
      heading: "Les patients font du mieux qu'ils peuvent.",
      text: "D'une part, ce présupposé nous prévient d'« invalider » les efforts que les patients fournissent au quotidien pour « s'en sortir », quand bien même ces efforts puissent être maladroits, insuffisants ou contreproductifs.",
    },
    {
      heading:
        "Les patients ont besoin de « mieux faire », « faire davantage d'efforts » et « être plus motivés » vers le changement.",
      text: "L'aspect dialectique contenu dans ces deux premiers présupposés est essentiel : les patients font de leur mieux aujourd'hui et, en même temps, ont besoin de faire mieux demain.",
    },
    {
      heading: "Les patients veulent aller mieux et gagner en qualité de vie.",
      text: "Ce présupposé nous prévient d'« invalider » la situation actuelle des patients, et nous invite à tenir compte de toutes les « contingences » qui puissent faciliter ou entraver le chemin vers le changement.",
    },
    {
      heading:
        "Quand bien même les patients ne sont pas responsables de tous leurs problèmes, ils doivent tous les résoudre.",
      text: "Ce présupposé implique, pour le patient et pour le thérapeute, d'accepter « radicalement » la réalité que nous ne pouvons pas changer. Cette « acceptation radicale » ne signifie pas que nous approuvons ou nous soumettons à la réalité qui nous fait souffrir mais que nous la reconnaissons comme un « fait », condition indispensable pour nous adapter et résoudre nos problèmes.",
    },
    {
      heading:
        "Les patients doivent apprendre des nouvelles stratégies dans tous les domaines signifiants de leurs vies.",
      text: "Ce présupposé nous rappelle que si les patients commencent par apprendre des nouvelles stratégies dans les aspects de leurs vies qui les font souffrir, la finalité de la thérapie est de généraliser ces apprentissages à tous les domaines essentiels de leur existence afin de susciter un « vrai changement ».",
    },
    {
      heading: "Les patients ne peuvent pas échouer en thérapie.",
      text: "Ce présupposé reconnaît que DBT ne peut pas être efficace pour tous les patients quand bien même le traitement ait été conduit convenablement.",
    },
    {
      heading:
        "Les patients souffrant de dysrégulation émotionnelle et les thérapeutes qui les accompagnent ont besoin d'être soutenus.",
      text: "Ce présupposé met l'accent dans un pilier du dispositif DBT : les réunions d'équipe au cours desquelles, non seulement les thérapeutes se sentent « contenus » et « portés » par le collectif, mais aussi, la réflexion d'équipe garantit un regard pluriel, global et tiers, sur le déroulement du traitement et sur la relation patient-thérapeute.",
    },
    {
      heading:
        "La vie d'un patient souffrant de dysrégulation émotionnelle peut être insupportable.",
      text: "Ce présupposé porte un regard « dialectique » sur les situations de souffrance intense avec risque suicidaire : nous pouvons mettre fin à nos jours ou choisir le changement.",
    },
  ];

  return (
    <div style={styles.carouselContainer}>
      <Carousel
        controls={true}
        indicators={true}
        interval={5000}
        fade={true}
        nextLabel=""
        prevLabel=""
      >
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <div style={styles.carouselItem}>
              <div style={styles.overlay}></div>
              <div style={styles.content}>
                <h2 style={styles.carouselHeading}>{item.heading}</h2>
                <p style={styles.carouselText}>{item.text}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

const DBT1 = () => {
  return (
    <>
      <h1
        className="mb-5 align-items-center"
        style={{ zIndex: 1, color: "#105575", textAlign: "center", paddingTop:"3.5rem"}}
      >
        <strong>Les présupposés de la DBT</strong>
      </h1>
      <Container>
        <div className="d-flex">
          <div className="col-md-12">
            <p>
              La DBT propose un « renversement » de la compréhension de la
              souffrance psychique et de la relation de soin. Les « présupposés »
              sont des postulats de base que tout thérapeute en DBT se doit
              d'intégrer.
            </p>
            <p>
              L'aspect « dialectique » de ces présupposés leur donne le relief
              particulier de trouver une dynamique constructive à la synthèse
              des aspects contradictoires de la réalité. S'inspirant de Hegel,
              Marsha M. Linehan propose d'accepter que la réalité est faite de
              l'union des contraires. L'enjeu dialectique est donc de faire
              coexister deux réalités qui apparaissent opposées :{" "}
              <strong>l'acceptation</strong> et le <strong>changement</strong>.
            </p>
            <br/>
          </div>
        </div>
      </Container>
      <ResponsiveCarousel />
    </>
  );
};

export default DBT1;
