import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/Galets_3.jpeg";
import { Tooltip } from "react-tooltip";

const DBT = () => {
  return (
    <>
      <div id="section1">
        <div
          className="d-flex flex-column align-items-center justify-content-center text-center text-white"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "80vh",
            position: "relative",
          }}
        >
          <h1
            className="display-4"
            style={{
              textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
              fontWeight: "bold",
            }}
          >
            Qu'est-ce que la Dialectical Behavior Therapy ?
          </h1>
          <div
            className="position-absolute top-0 start-0 end-0 bottom-0"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
          />
        </div>{" "}
        <Container className="py-5">
          <Row>
            <Col md={12}>
              <p>
                Les années quatre-vingt ont vu apparaître les thérapies de la «
                troisième vague », où l'émotion joue un rôle central. Selon
                cette approche, la dysrégulation émotionnelle serait à l'origine
                de la dysrégulation de la pensée et, in fine, des comportements,
                créant des conduites problèmes. Si l'émotion est au cœur des
                pensées et des comportements, l'enjeu thérapeutique est
                d'identifier l'émotion dysrégulée grâce à la Pleine Conscience.
                C'est en acceptant cette réalité que nous ne pouvons pas
                changer, qu'il est possible d'apprendre à ne plus souffrir. Cela
                passe par un engagement pour « inventer ensemble » de nouvelles
                stratégies adaptées de fonctionnement.
              </p>
            </Col>

            <Col md={12}>
              <p>
                Dans ce contexte, plusieurs thérapies de la « troisième vague »
                émergent : d'une part, la thérapie d'acceptation et d'engagement
                (Acceptance and Commitment Therapy ou ACT), développée par
                Steven Hayes et Robert Zettle et, d'autre part, les thérapies
                fondées sur la pleine conscience (MBCT développée par Zindel
                Segal et al., MBSR développée par Jon Kabat-Zinn) et notamment
                la Thérapie Comportementale Dialectique ou Dialectical
                Behavioral Therapy (DBT) développée par Marsha M. Linehan.
              </p>
            </Col>
            <Col md={12} className="order-md-2">
              <div style={{ position: "relative", height: "50vh" }}>
                <iframe
                  src="https://www.youtube.com/embed/rU7Knx3VByA"
                  title="YouTube video player"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: 0,
                  }}
                />
              </div> 
         
            </Col>
          </Row>
        </Container>
        <div
  md={12}
  className="d-flex align-items-center justify-content-center p-4 position-relative"
  style={{ zIndex: 1, backgroundColor: "#273d4b" }}
>
  <div
    className="text-white"
    style={{ maxWidth: "69%", fontSize: "1.5rem", paddingRight: "40px", paddingLeft: "40px" }} // Ajout de padding ici
  >
    <p>
      Marsha M. Linehan a donc mis au point une thérapie faisant des
      emprunts à la&nbsp;
      <span
        id="psychanalyse"
        data-tooltip-content="mécanismes de défense, compulsion de répétition, relation transférentielle avec le thérapeute"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        psychanalyse
      </span>
      , aux&nbsp;
      <span
        id="neurosciences"
        data-tooltip-content="mécanismes neuro-biologiques de régulation de l’émotion, vulnérabilité innée et acquise au stress, hyperémotivité, hypersensorialité"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        neurosciences
      </span>
      , à la&nbsp;
      <span
        id="psychologie"
        data-tooltip-content="théorie bio-sociale, théorie de l’apprentissage"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        psychologie du développement
      </span>
      , à la&nbsp;
      <span
        id="attachement"
        data-tooltip-content="modèles relationnels"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        théorie de l’attachement
      </span>
      , à la&nbsp;
      <span
        id="institutionnelle"
        data-tooltip-content="le fait de la réflexion commune d’un groupe de thérapeutes"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        psychothérapie institutionnelle
      </span>
      , à la&nbsp;
      <span
        id="meditation"
        data-tooltip-content="pleine conscience"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        méditation Vipassana et bouddhisme Zen
      </span>
      , ainsi qu’à la&nbsp;
      <span
        id="philosophie"
        data-tooltip-content="la dialectique étant l’unité des contraires qui font la réalité"
        style={{ color: "#ff6347", fontWeight: "bold" }}
      >
        philosophie hégelienne
      </span>
      .
    </p>
  </div>

  {/* Custom Tooltip styles */}
  <Tooltip
    anchorId="psychanalyse"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
  <Tooltip
    anchorId="neurosciences"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
  <Tooltip
    anchorId="psychologie"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
  <Tooltip
    anchorId="attachement"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
  <Tooltip
    anchorId="institutionnelle"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
  <Tooltip
    anchorId="meditation"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
  <Tooltip
    anchorId="philosophie"
    style={{
      fontSize: "1.5rem",
      backgroundColor: "#333",
      padding: "10px",
      borderRadius: "10px",
    }}
  />
</div>

        <Container>
          <br />
          <br />
          <Row> 
         
            <Col md={6}>
              <p>
                Marsha M. Linehan a créé la Dialectical Behavioral Therapy (DBT)
                initialement conçue pour prendre en charge le trouble de la
                personnalité borderline (TPB). Ayant été elle-même hospitalisée
                en chambre d'isolement pendant des longs mois alors qu'elle
                était jeune adulte, Marsha M. Linehan a conçu la DBT dans les
                années quatre-vingt, à l'université de Washington à Seattle, en
                s'inspirant de son vécu de patiente. Son expérience des
                insuffisances des dispositifs de soins de l'époque pour la prise
                en charge des patients souffrant de TPB, l'a amené à se demander
                ce qui aurait pu lui porter secours aux moments les plus
                sombres. Cette démarche l'a conduite à mettre au point un corpus
                théorique et thérapeutique holistique et intégratif.
              </p>
            </Col>
            <Col md={6}>
              <div style={{ position: "relative", height: "50vh" }}>
                <iframe
                  src="https://www.youtube.com/embed/bULL3sSc_-I"
                  title="YouTube video player"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: 0,
                    borderRadius: "3%",
                  }}
                />
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md={12}>
              <p>
                De nos jours, il a été démontré scientifiquement que « DBT
                standard » est le protocole de soins le plus efficace dans le
                traitement du trouble de la personnalité borderline, améliorant
                la qualité de vie de près de 80% des patients au bout d’un an de
                prise en charge. Une fois que les patients ont appris à gagner
                en qualité de vie, ils peuvent accomplir leurs buts, véritables
                « raisons pour vivre », donnant du sens à l’existence.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul>
                <li>
                  Des protocoles de soin pour répondre concrètement aux
                  problèmes identifiés :
                  <ul>
                    <li>de 50% les tentatives de suicide</li>
                    <li>de 53% la sollicitation des urgences psychiatriques</li>
                    <li>de 73% les hospitalisations en psychiatrie</li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>{" "}
          <Row>
            <Col md={12}>
              {" "}
              <strong>
                <p style={{ fontSize: "1rem" }}>
                  Linehan, M. M., & Heard, H. L. (1999). Borderline personality
                  disorder : Costs, course, and treatment outcomes. In N. E.
                  Miller & K. M. Magruder (Eds.), Cost-effectiveness of
                  psychotherapy : A guide for practitioners, researchers, and
                  policymakers (pp. 291–305). Oxford University Press.
                </p>
              </strong>
            </Col>
          </Row>
          <br />
          <br />{" "}
          <Row>
            <Col md={12}>
              <p>
                De plus, des études allemandes ont démontré que si la DBT était
                prodiguée à la moitié des patients souffrant de TPB, les
                dépenses en santé outre-Rhin seraient réduites de 1,5 milliard
                d’euros. Ainsi, certaines régions allemandes envisagent
                d’enseigner à l’école primaire les compétences de régulation
                émotionnelle de DBT, dans un but de prévention première en santé
                mentale. Enfin, la solidité scientifique de DBT est à l’origine
                de son implantation dans au moins 23 pays (USA, Canada, les pays
                ibéro-américains, Royaume Uni, Allemagne, Australie, Japon).
                Aujourd’hui, la France commence à s’intéresser à ce dispositif
                malgré sa fidélité à la psychanalyse.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <br />
      <br />
      <br />
    </>
  );
};

export default DBT; 