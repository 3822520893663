import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const NavbarComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const [showNavbar] = useState(true);

  const closeMenu = () => setExpanded(false);

  const scrollToTop = () => {
    setExpanded(false);
    window.setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <>
      <Navbar
        expanded={expanded}
        expand="lg"
        className={`navbar-custom ${showNavbar ? "show" : "hide"}`}
        fixed="top"
      >
        <Container>
          <Navbar.Brand
            as={Link}
            to="/"
            onClick={scrollToTop}
            className="navbar-brand-custom"
          >
            CENTRE FRANCOPHONE DE DBT
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className="navbar-toggle-custom"
          >
            {expanded ? <FaTimes /> : <FaBars />}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown title="LA THÉRAPIE DBT" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/dbt"
                  onClick={() => {
                    closeMenu();
                    scrollToTop();
                  }}
                >
                  Qu'est-ce que la DBT ?
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/dbt1"
                  onClick={() => {
                    closeMenu();
                    scrollToTop();
                  }}
                >
                  Les présupposés de la DBT
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/prise-en-charge"
                  onClick={() => {
                    closeMenu();
                    scrollToTop();
                  }}
                >
                  La prise en charge
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                as={Link}
                to="/therapy"
                onClick={() => {
                  closeMenu();
                  scrollToTop();
                }}
              >
                POUR QUI ?
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/therapeutes"
                onClick={() => {
                  closeMenu();
                  scrollToTop();
                }}
              >
                L'EQUIPE ET NOS PARTENAIRES
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => {
                  closeMenu();
                  scrollToTop();
                }}
              >
                INFOS PRATIQUES
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <style jsx>{`
        .navbar-custom {
          background-color: #ffffff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          height: 80px;
          transition: transform 0.3s ease-in-out;
        }

        .navbar-custom.show {
          transform: translateY(0);
        }

        .navbar-custom.hide {
          transform: translateY(-100%);
        }

        .navbar-brand-custom {
          font-weight: bold;
          color: #333333;
          padding-top: 15px; /* Espacement en haut */
        }

        .navbar-toggle-custom {
          border: none;
          font-size: 1.5rem;
          margin-top: 10px; /* Ajustement du positionnement du bouton */
        }

        @media (max-width: 991px) {
          .navbar-custom {
            display: flex;
            justify-content: center; /* Centrer le contenu de la navbar */
          }

          .navbar-brand-custom {
            font-size: 1rem; 
            text-align: center; 
          }

          .navbar-collapse {
            background-color: #ffffff;
            padding: 1rem;
            border-radius: 0 0 5px 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .navbar-toggle-custom {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      `}</style>
    </>
  );
};

export default NavbarComponent;
