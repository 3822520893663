import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image from "../../images/allure_therapy-DBT.jpg";
import pic1 from "../../images/Carte journalière.png";
import pic2 from "../../images/Motivation.png";
import pic3 from "../../images/balans-met-stenen.jpeg";

const PsychotherapieIndividuelle = () => {
  const pics = [pic1, pic2, pic3];

  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center text-white"
        style={{
          backgroundImage: `url(${image})`, // Corrected property
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
          position: "relative",
          textAlign: "center",
        }}
      >
        <h1
          className="display-4"
          style={{
            textShadow: "2px 2px 8px rgba(0, 0, 0, 0.8)",
            fontWeight: "bold",
            paddingTop: "10rem",
          }}
        >
          La psychothérapie individuelle
        </h1>
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        />
      </div>

      <Container className="my-5">
        {/* Section 1 */}
        <Row className="mb-5 align-items-center">
          <Col md={12}>
            <p>
              La DBT enseigne aux patients des compétences (skills), véritables
              « outils » qu’ils peuvent s’approprier pour réguler leurs
              émotions. Dans le même temps, les patients peuvent être suivis en
              psychothérapie individuelle pour mieux s’approprier les
              compétences et les généraliser à leur vie quotidienne.
            </p>
            <br />
            <h2 style={{ color: "#105575" }}>
              1. La définition des buts et objectifs thérapeutiques
            </h2>
            <br />
            <p>
              L’enjeu initial de la psychothérapie individuelle en DBT est celui
              de définir les objectifs et les buts thérapeutiques pour chaque
              patient et pour chacune des quatre étapes du traitement. Dans les
              phases initiales du traitement, le patient et le thérapeute
              identifient les buts et les « raisons pour vivre » du patient, qui
              suscitent la motivation à s’engager dans la thérapie. Afin
              d'atteindre ses buts, le patient définit avec son thérapeute des
              objectifs et identifie les « conduites problèmes » qui le font
              souffrir, afin de les remplacer par des stratégies adaptées, en
              utilisant les compétences apprises en groupe. Enfin, le thérapeute
              individuel aide son patient à identifier parmi ses objectifs,
              lesquels doivent être priorisés.
            </p>
            <p>
              Afin d'atteindre ses buts, le patient définit avec son thérapeute
              des objectifs et identifie les « conduites problèmes » qui le font
              souffrir, afin de les remplacer par des stratégies adaptées, en
              utilisant les compétences apprises en groupe. Enfin, le thérapeute
              individuel aide son patient à identifier parmi ses objectifs,
              lesquels doivent être priorisés.
            </p>
          </Col>
        </Row>

        {/* Section 2 */}
        <Row className="mb-5 align-items-center">
          <Col md={12}>
          <h2 style={{ color: "#105575" }}>2. La motivation</h2>
          <br />            <p>
              Il est aussi question en thérapie individuelle de promouvoir la
              motivation du patient, car d’elle dépend le fait que le patient{" "}
              <strong>s’engage</strong>, <strong>persiste</strong> et{" "}
              <strong>accomplisse</strong> son parcours en DBT et, de ce fait,
              elle concerne toutes les étapes de la prise en charge.
            </p>{" "}
          </Col>
          <Col md={6}>
           

            <p>
              Lors des phases initiales du traitement, la motivation consiste à
              rappeler que tous les efforts auxquels le patient doit consentir
              ont du sens car ils améliorent sa qualité de vie, résolvent ses
              problèmes et l’approchent de ses raisons de vivre. Dans ce sens,
              une stratégie fréquemment utilisée par le thérapeute consiste à
              contempler avec le patient <strong>ses options</strong>.
            </p>
          </Col> <Col md={6}>
            <img src={pics[1]} alt="pic 2" className="img-fluid rounded" />
            <br />
          </Col>
         
        </Row>

        {/* Section 3 */}
        <Row className="mb-5 align-items-center">
          <Col md={6}>
            {" "}
            <h2 style={{ color: "#105575" }}>
              3. Les trois étapes de la thérapie
            </h2>
            <br />
            <br />
            <img
              src={pics[2]}
              alt="pic 3"
              style={{ width: "90%" }}
              className="img-fluid rounded"
            />
          </Col>
          <Col md={6}>
            <br />
            <br />
            <ol>
              <li>
                <strong>Première étape :</strong> L’essentiel de la motivation
                consiste à inviter le patient à persister dans l’appropriation
                et l’utilisation des compétences et des stratégies adaptées.
              </li>
              <br />
              <li>
                <strong>Deuxième étape :</strong> Le patient est encouragé à
                aborder les aspects traumatiques de sa problématique, en
                passant, si nécessaire, par une psychothérapie d’exposition.
              </li>
              <br />
              <li>
                <strong>Étapes successives :</strong> La motivation implique le
                soutien dans l’affirmation du self du patient et
                l’accomplissement de ses raisons de vivre.
              </li>
            </ol>
          </Col>
        </Row>

        {/* Section 4 */}
        <Row className="mb-5 align-items-center">
          <Col md={12}>
            <h2 style={{ color: "#105575" }}>
              4. La carte journalière et les tâches à la maison
            </h2>
            <br />
            <p>
              L’observation en psychothérapie individuelle des mouvements
              émotionnels quotidiens, ainsi que la manière dont,
              progressivement, le patient acquière des compétences pour les
              réguler et gagner en qualité de vie, est une expérience partagée
              fondamentale en DBT. L’outil qui rend possible cette observation
              est la carte journalière, véritable journal de bord de la vie
              émotionnelle.
            </p>
          </Col>
          <Col md={6} lg={8}>
            <img src={pics[0]} alt="pic 1" className="img-fluid rounded" />
          </Col>
          <Col>
            {" "}
            <p>
              Le remplissage par le patient de cette carte journalière fait
              partie des <strong>engagements</strong> du patient et de son
              psychothérapeute individuel, de même que{" "}
              <strong> les tâches à la maison</strong> , dont ils conviennent.
              Les tâches à la maison concernent, souvent, la mise à l’essai de
              nouvelles stratégies pour résoudre les problèmes du patient.
            </p>
          </Col>
        </Row>

        {/* Section 5 */}
        <Row className="mb-5 align-items-center">
          <Col md={12}>
            <h2 style={{ color: "#105575" }}>5. L'évaluation</h2>
            <br />
            <p>
              La DBT est un protocole fondé sur l’équilibre entre la{" "}
              <strong>validation</strong> qui rassure et le{" "}
              <strong>changement</strong> qui résout les problèmes des patients.
              De ce fait, le thérapeute individuel en DBT évalue en permanence
              toutes les circonstances qui maintiennent les stratégies
              inadaptées du patient, ainsi que toutes les circonstances qui
              l’empêchent d’acquérir des nouvelles stratégies adaptées,
              notamment par l’observation des cartes journalières.
            </p>

            <ul>
              {" "}
              <p>
                Dans cet effort d'évaluation, le clinicien peut se servir de :
              </p>{" "}
              <li>
                <strong>L’analyse en chaîne</strong>: pour comprendre les
                conduites problèmes <strong>par excès</strong>, telles que les
                auto-lésions ou les intoxications.
              </li>
              <li>
                <strong>L’analyse des liens manquants</strong> : pour comprendre
                les conduites problèmes <strong>par défaut</strong>, telles que
                les absences aux séances ou la non-utilisation des compétences.
              </li>
              <li>
                <strong>
                  La Linehan Risk Assessment and Management Protocol
                </strong>{" "}
                (L-RAMP) : outil d’évaluation spécifique en DBT dans les
                situations qui comportent un risque suicidaire.
              </li>
              <li>
                L’utilisation d’autres ressources cliniques : bilans
                neuro-psychologiques, collaboration avec la famille, réseaux
                sociaux, psychiatres.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PsychotherapieIndividuelle;
