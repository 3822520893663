import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/Contact entre séances 1.jpg";

const styles = {
  section: {
    padding: "5rem 0",
  },
  heading: {
    color: "#105575",
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "1.5rem",
    textAlign: "center",
  },
  paragraph: {
    fontSize: "1.25rem",
    textAlign: "justify",
    marginBottom: "1.5rem",
  },
  list: {
    fontSize: "1.25rem",
    listStyleType: "none",
    padding: 0,
    marginLeft: "2rem",
    marginBottom: "1.5rem",
  },
  listItem: {
    marginBottom: "0.5rem",
  },
  image: {
    width: "80%",
    borderRadius: "30px",
    boxShadow: "0 8px 8px rgba(0, 0, 0, 0.1)",
  },
  iframe: {
    borderRadius: "30px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};

const ContactsEntreSeances = () => (
  <section className="py-5">
    <Container>
      <Row className="mb-5 align-items-center">
        <Col md={12} style={{ zIndex: 1 }}>
          <h1 style={{ color: "#105575", textAlign: "center" }}>
            <strong>Les contacts entre les séances</strong>
          </h1>
          <br />
          <p>
            Le contact entre les séances est le troisième mode de psychothérapie
            en DBT. La raison d’être de ce mode de psychothérapie est de fournir
            aux patients un cadre thérapeutique sécurisant, réel et concret, ici
            et maintenant, en cas d’activation émotionnelle ou de crise, entre
            deux séances.
          </p>
          <p>
            Il s’agit donc de faire sentir aux patients que « les interstices »
            sont aussi compris dans le protocole de soin. Le contact entre les
            séances peut prendre plusieurs formes et il convient de le définir
            avec chaque patient, en fonction de ses besoins.
          </p>
          <p>
            Aussi, il est indispensable de donner un cadre sous forme
            d’engagement mutuel, à cette disponibilité du psychothérapeute, car
            il existe le risque que le contact entre les séances soit transformé
            par le patient en conduite qui porte atteinte aux soins. Il peut
            d’agir de :
          </p>
        </Col>
        <Col md={6} style={{ marginTop: "-50px" }}></Col>
      </Row>

      <Row className="mb-5 align-items-center">
        <Col md={12} lg={6} style={{ zIndex: 1 }}>
          <h2 style={{ color: "#105575" }}>Téléphone</h2>
          <p>
            le patient dispose du téléphone personnel du thérapeute et le
            contact, à l’intérieur du cadre et dans les conditions convenues
            mutuellement, une dizaine de minutes, en cas d’activation
            émotionnelle. Il ne s’agit pas de faire une séance de psychothérapie
            au téléphone. Il s’agit d’accompagner et de soutenir le patient, par
            téléphone, pour qu’il puisse utiliser les compétences de survie ou
            mettre en place les stratégies adaptées mises au point en
            psychothérapie.
          </p>
        </Col>{" "}
        <Col md={12} lg={6} style={{ zIndex: 1 }}>
          <img
            src={backgroundImage}
            alt="Tolérance à la Détresse"
            style={styles.image}
          />
        </Col>{" "}
        <Col md={6} lg={12} style={{ zIndex: 1 }}>
          <p>
            Un proverbe français dit « il faut battre le fer tant qu’il est
            chaud ». Ici, il s’agirait que le patient fasse l’expérience qu’il
            est en mesure de survivre et de réguler une crise, à chaud, avec le
            soutien de son thérapeute et par l’utilisation des outils de la DBT.
          </p>
        </Col>
        <Col md={6} lg={12} style={{ zIndex: 1 }}>
          <br />
          <h2 style={{ color: "#105575" }}>SMS</h2>
          <p>
            Le patient peut également envoyer un SMS dans des conditions
            analogues à celles du contact téléphonique. Le SMS permet un soutien
            rapide et concis, aidant le patient à se réguler en utilisant les
            compétences de DBT apprises.
          </p>
        </Col>
        <Col md={6} lg={6} style={{ marginTop: "-50px" }}></Col>
      </Row>

      <Row className="mb-5">
        <Col md={6} lg={12} style={{ zIndex: 1 }}>
          <h2 style={{ color: "#105575" }}>Courriers</h2>
          <p>
            Le patient peut aussi utiliser des courriers pour exprimer ses
            pensées et émotions entre les séances. Cela permet un suivi plus
            réfléchi et détaillé, facilitant une prise de recul et une analyse
            plus approfondie des situations.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

export default ContactsEntreSeances;
