import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/Groupe_DBT_1.png";
import { Link } from "react-router-dom";

const GroupeCompetence = () => (
  <>
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "50vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "2rem",
      }}
    >
      <div
        className="position-absolute top-0 start-0 end-0 bottom-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      />
      <div
        className="position-relative d-flex flex-column align-items-center justify-content-center h-100"
        style={{ color: "#ffffff", textAlign: "center", padding: "2rem" }}
      >
        <h1
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#ffffff",
            textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          Le groupe d’entraînement aux compétences{" "}
        </h1>
      </div>
    </div>

    <Container>
      <Row className="justify-content-center">
        <Col md={12} lg={8} style={{ marginBottom: "2rem" }}>
          <p style={{ margin: "1rem auto" }}>
            La psycho-éducation est l’un des piliers de la DBT. Un des
            principaux apports de la DBT à la santé mentale est la transmission
            des{" "}
            <Link to="/regulation-emotionnelle">
              compétences de régulation émotionnelle
            </Link>{" "}
            , véritable transcription des neurosciences à la vie quotidienne.
            L’apprentissage de ces compétences est indispensable aux patients,
            pour qu’ils puissent abandonner leurs conduites problème, et leur
            permettre d’inventer de nouvelles stratégies adaptées, afin de
            gagner en qualité de vie.
          </p>

          <p style={{ margin: "1rem auto" }}>
            Le groupe d’entraînement aux compétences est l’un des quatre modes
            de psychothérapie en DBT. Il s’agit de l’apprentissage en groupe des
            quatre modules de compétences de régulation émotionnelle. Le groupe
            est constitué d’une dizaine de patients, accompagnée de deux
            co-thérapeutes et se réunit à raison d’une séance hebdomadaire d’une
            heure et demie, pendant 6 mois.
          </p>
        </Col>
      </Row>
    </Container>
  </>
);

export default GroupeCompetence;
