import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import backgroundImage from "../../images/Thérapie_individuelle.jpg";

const PourQui = () => {
  const location = useLocation();
  const sectionsRef = useRef({});

  const scrollToSection = (id) => {
    const element = sectionsRef.current[id];
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  };

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      scrollToSection(id);
    }
  }, [location]);

  return (
    <>
      {/* Image de fond avec superposition de texte */}
      <div
        className="d-flex flex-column align-items-center justify-content-center text-center text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "80vh",
          position: "relative",
        }}
      >
        <h1
          className="display-4"
          style={{
            textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
            fontWeight: "bold",
          }}
        >
          POUR QUI ?
        </h1>
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        />
      </div>

      {/* Section pour Adultes */}
      <section
        ref={(el) => sectionsRef.current['adultes'] = el}
        className="py-5"
        style={{ minHeight: "100vh", backgroundColor: "#f9f9f9" }}
      >
        <Container>
          <Row className="text-center mb-5">
            <Col md={12}>
              <h1
                className="mb-4 text-custom-primary"
                style={{
                  color: "#003366",
                  fontSize: "2.5rem",
                  fontWeight: "700",
                }}
              >
                <strong>Adultes</strong>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul className="list-unstyled">
                <li ref={(el) => sectionsRef.current['dbt-standard'] = el} className="mb-5" style={{ fontSize: "1.5rem", color: "#333", lineHeight: "1.6" }}>
                  <strong style={{ fontSize: "1.75rem", color: "#003366" }}>
                    Protocole DBT Standard :
                  </strong>{" "}
                  Indiqué pour toutes situations de dysrégulation émotionnelle chez l'adulte : troubles de la personnalité, troubles de l'humeur, troubles anxieux, troubles des conduites alimentaires. 80 % d'efficacité au bout d'un an de traitement.
                </li>
                <li ref={(el) => sectionsRef.current['dbt-sud'] = el} className="mb-5" style={{ fontSize: "1.5rem", color: "#333", lineHeight: "1.6" }}>
                  <strong style={{ fontSize: "1.75rem", color: "#003366" }}>
                    Protocole DBT Substance Use Disorder :
                  </strong>{" "}
                  Indiqué pour toutes les formes d'addiction.
                </li>
                <li ref={(el) => sectionsRef.current['dbt-tspt'] = el} className="mb-5" style={{ fontSize: "1.5rem", color: "#333", lineHeight: "1.6" }}>
                  <strong style={{ fontSize: "1.75rem", color: "#003366" }}>
                    Protocoles DBT pour le TSPT complexe
                  </strong>{" "}
                  « Martin Bohus ; Mélanie Harned »
                  <strong style={{ fontSize: "1.75rem", color: "#003366" }}> : </strong>
                  indiqués en cas de trauma complexe . Ces deux protocoles associent les compétences de régulation émotionnelle à la thérapie d'exposition prolongée. Evidence Based : 90 % d'efficacité au bout d'un an de traitement.
                </li>
                <li ref={(el) => sectionsRef.current['dbt-ro'] = el} className="mb-5" style={{ fontSize: "1.5rem", color: "#333", lineHeight: "1.6" }}>
                  <strong style={{ fontSize: "1.75rem", color: "#003366" }}>
                    Protocole DBT Radically Open :
                  </strong>{" "}
                  La thérapie comportementale dialectique radicalement ouverte (RO-DBT) est une thérapie destinée aux personnes ayant un style d'adaptation trop contrôlé et souffrant d'un trouble résistant au traitement, comme la dépression et l'anorexie. La RO-DBT aide à lutter contre la solitude émotionnelle et enseigne des compétences utiles pour mieux prendre conscience de la manière dont les signaux sociaux affectent les relations, ainsi que pour être plus ouvert et réceptif à l'environnement afin d'apprendre. La RO-DBT dispose d'une base factuelle solide avec cinq essais publiés à ce jour (par exemple <strong>Lynch, Hempel, & Dunkley</strong>, 2015).
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Section pour Enfants & Adolescents */}
      <section
        ref={(el) => sectionsRef.current['enfants-adolescents'] = el}
        className="py-5"
        style={{ backgroundColor: "#fff4f4", minHeight: "100vh" }}
      >
        <Container>
          <Row className="text-center mb-5">
            <Col md={12}>
              <h1
                className="mb-4 text-custom-primary"
                style={{
                  color: "#b30000",
                  fontSize: "2.5rem",
                  fontWeight: "700",
                }}
              >
                <strong>Enfants & Adolescents</strong>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul className="list-unstyled">
                <li ref={(el) => sectionsRef.current['dbt-teens'] = el} className="mb-5" style={{ fontSize: "1.5rem", color: "#333", lineHeight: "1.6" }}>
                  <strong style={{ fontSize: "1.75rem", color: "#b30000" }}>
                    Protocole DBT Teens :
                  </strong>{" "}
                  Protocole développé par <strong>Jill H. Rathus</strong> et <strong>Alec L. Miller</strong> (2015). La DBT pour adolescents consiste à enseigner aux adolescents et aux familles l'utilisation de compétences spécifiques de régulation émotionnelle. Ce traitement peut être proposé à un large éventail de diagnostics partageant la dysrégulation émotionnelle. Selon la littérature scientifique, DBT Teens réduit de 44% les auto-lésions, de 31% les idées suicidaires et de 32% les tentatives de suicide chez les adolescents entre 12 ans et 18 ans. L'efficacité globale du traitement est estimée entre 74% et 80% (
                  <a
                    href="https://doi.org/10.1017/S0033291721001355"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Oswald D. Kothgassner et al., 2021
                  </a>{" "}
                  ;{" "}
                  <a
                    href="https://doi.org/10.1016/j.brat.2023.104408"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lauren J. Harvey et al. 2023
                  </a>
                  ).
                </li>
                <li ref={(el) => sectionsRef.current['dbt-child'] = el} className="mb-5" style={{ fontSize: "1.5rem", color: "#333", lineHeight: "1.6" }}>
                  <strong style={{ fontSize: "1.75rem", color: "#b30000" }}>
                    Protocole DBT Child :
                  </strong>{" "}
                  Inspiré du protocole de <strong>Francheska Perepletchikova</strong>, ce protocole a pour objectif la diminution du risque psychopathologique en apprenant aux parents à créer un environnement de validation et permettant de généraliser les techniques DBT enseignées dans le cadre des interactions parents-enfants.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PourQui;