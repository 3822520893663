import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../../images/Groupe_DBT_3.png";
import image2 from "../../images/allure_therapy-DBT.jpg";
import image3 from "../../images/Contact entre séances 1.jpg";
import image4 from "../../images/Groupe_therapeutes.jpg";
import image5 from "../../images/bol_1.jpeg";
import image6 from "../../images/TIPP_skills.png";
import image7 from "../../images/istockphoto-1358081661-612x612.jpg";
import image8 from "../../images/Efficacité-interpersonnelle.jpg";

const GroupeCompetence = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClick = (path) => {
    scrollToTop();
    setTimeout(() => {
      navigate(path);
    }, 300);
  };

  const renderCard = (card, index) => (
    <Col key={index} xs={12} md={6} lg={6} className="mb-4">
      <Card
        style={{
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          color: "#000000",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "40%", height: "300px", overflow: "hidden" }}>
          <img
            src={card.image}
            alt={card.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px 0 0 10px",
              cursor: "pointer",
            }}
            onClick={() => handleClick(card.path || card.link)}
          />
        </div>
        <Card.Body
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Card.Title
              style={{
                fontSize: "1.2rem",
                color: "#333333",
                fontWeight: "bold",
              }}
            >
              <Link
                to={card.path || card.link}
                style={{
                  textDecoration: "none",
                  color: "#333333",
                  fontWeight: "bold",
                }}
                onClick={() => handleClick(card.path || card.link)}
              >
                {card.title}
              </Link>
            </Card.Title>
            <p
              style={{
                color: "#666666",
                fontSize: "0.9rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "6",
                WebkitBoxOrient: "vertical",
              }}
            >
              {card.description}
            </p>
          </div>
          <Link
            to={card.path || card.link}
            style={{
              textDecoration: "none",
              color: "#86b0be",
              fontWeight: "bold",
            }}
            onClick={() => handleClick(card.path || card.link)}
          >
            En savoir plus
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );

  const cardData = [
    {
      title: "Le groupe d'entraînement aux compétences",
      description:
        "La psycho-éducation est l'un des piliers de la DBT. Un des principaux apports de la DBT à la santé mentale est la transmission des « compétences de régulation émotionnelle », véritable transcription des neurosciences à la vie quotidienne.",
      image: image1,
      path: "/groupe_entrainement",
    },
    {
      title: "La psychothérapie individuelle",
      description:
        "L'enjeu initial de la psychothérapie individuelle en DBT est celui de définir les objectifs et les buts thérapeutiques pour chaque patient et pour chacune des quatre étapes du traitement.",
      image: image2,
      path: "/psychotherapie_individuelle",
    },
    {
      title: "Les contacts entre les séances",
      description:
        "Le contact entre les séances est le troisième mode de psychothérapie en DBT. La raison d'être de ce mode de psychothérapie est de fournir aux patients un cadre thérapeutique sécurisant, réel et concret, ici et maintenant, en cas d'activation émotionnelle ou de crise, entre deux séances.",
      image: image3,
      path: "/contact-entre-seances",
    },
    {
      title: "La réunion d'équipe des thérapeutes DBT",
      description:
        "La réunion d'équipe des psychothérapeutes DBT est un élément essentiel du protocole DBT. C'est une forme de '' thérapie pour les thérapeutes'', qui a lieu chaque semaine et dure entre une heure et une heure et demie, selon la taille de l'équipe.",
      image: image4,
      path: "/reunion-equipe",
    },
  ];

  const moduleData = [
    {
      title: "Pleine Conscience",
      description:
        "Les compétences de Pleine Conscience sont la base de la régulation émotionnelle. Apprises en groupe et intégrées en psychothérapie individuelle, elles sont indispensables pour l'assimilation des autres modules.",
      image: image5,
      link: "/mindfulness",
    },
    {
      title: "Tolérance à la Détresse",
      description:
        "Les compétences de tolérance à la détresse apportent un soulagement immédiat, ici et maintenant, à la souffrance aiguë et minimisent le risque d'aggraver la situation par des conduites impulsives (urgences à agir). Ces compétences se fondent sur la philosophie Zen : accepter la réalité ne signifie pas l'approuver et la souffrance vient de la non-acceptation de cette réalité.",
      image: image6,
      link: "/distress",
    },
    {
      title: "Régulation des émotions",
      description:
        "L'objectif de ce module est de modifier les réponses émotionnelles et réduire la vulnérabilité émotionnelle par la psycho-éducation et l'utilisation de compétences.",
      image: image7,
      link: "/regulation-emotionnelle",
    },
    {
      title: "Efficacité Interpersonnelle",
      description:
        "Ce module permet de construire des liens sains avec autrui, en s'appuyant sur la pleine conscience : sans agressivité et tenant compte d'autrui. Trouver un équilibre entre nos besoins et ceux d'autrui.",
      image: image8,
      link: "/interpersonal-relationship",
    },
  ];

  return (
    <section
      className="py-5"
      style={{ backgroundColor: "#F9F0E5", minHeight: "90vh" }}
    >
      <Container>
        <h1 className="text-center mb-5">
          <strong>La prise en charge DBT</strong>
        </h1>
        <p className="text-center mb-4">
          La DBT a été conçue comme un dispositif multimodale soutenu par une
          équipe de thérapeutes. Il s'agit d'un dispositif hautement structuré
          et validé scientifiquement. La DBT est un traitement intensif, d'une
          durée d'au moins un an, fondé sur quatre modes de psychothérapie :
        </p>
      </Container>

      <Container className="my-5">
        <Row className="justify-content-center">
          {cardData.map(renderCard)}
        </Row>
        <Row className="mb-5 justify-content-center">
          <h1
            style={{ color: "#105575", marginBottom: "2rem", marginTop: "2rem", textAlign: "center" }}
          >
            <strong>Les 4 modules d'entraînement aux compétences :</strong>
          </h1>
          <Col xs={12} md={10} lg={8}>
            <div
              className="video-container"
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: "50vh",
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
                src="https://www.youtube.com/embed/k78I1q8h-vs"
                title="TIPP Training Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {moduleData.map(renderCard)}
        </Row>
      </Container>
    </section>
  );
};

export default GroupeCompetence;