import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../../images/istockphoto-1358081661-612x612.jpg";

const DBT9 = () => (
  <>
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "50vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "2rem",
      }}
    >
      <div
        className="position-absolute top-0 start-0 end-0 bottom-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      />
      <div
        className="position-relative d-flex flex-column align-items-center justify-content-center h-100"
        style={{ color: "#ffffff", textAlign: "center", padding: "2rem" }}
      >
        <h1
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            color: "#ffffff",
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          La Régulation Émotionnelle
        </h1>
      </div>
    </div>

    <Container>
      <Row className="justify-content-center">
        <Col md={12} lg={8} style={{ marginBottom: "2rem" }}>
          <p style={{ margin: "1rem auto" }}>
            L’objectif de ce module est de modifier les réponses émotionnelles
            et réduire la vulnérabilité émotionnelle par la psycho-éducation et
            l’utilisation de compétences.
          </p>

          <p style={{ margin: "1rem auto" }}>
            « Agir à l’opposé » est la plus connue et utilisée des compétences
            de régulation émotionnelle. Lorsqu’une émotion désagréable ne
            correspond pas aux faits et son activation nous pousse à obéir à
            l’urgence à agir ou à adopter une conduite problème inefficace, la
            compétence nous aide à :{" "}
          </p>
          <ul style={{ maxWidth: "800px", margin: "0 auto" }}>
            <li style={{ marginBottom: "0.5rem" }}>
              Identifier l’émotion activée et la conduite qu’elle nous impose,
              notamment par l’utilisation de compétences de « pleine conscience
              ».
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              Faire l’opposé de ce que la conduite nous dicte : boire → rester
              sobre, se battre → s’éloigner, se scarifier → ne pas se blesser...
              Et cela en le faisant entièrement avec le corps, les pensées et
              les actions.
            </li>
            <li style={{ marginBottom: "0.5rem" }}>
              Pratiquer l’action opposée encore et encore jusqu’à ce qu’elle
              devienne la réponse automatique.
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-5 justify-content-center">
        <Col md={12}>
          <div className="ratio ratio-16x9" style={{ margin: "2rem 0" }}>
            <iframe
              src="https://www.youtube.com/embed/MqsFhm0gIX0"
              title="TIPP Training Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: "15px" }}
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  </>
);

export default DBT9;
